export const useAuthorizationStore = defineStore('authStore', () => {
  const useAccountInfo = useAccountProfileStore();
  const {accountGeneralInfo} = storeToRefs(useAccountInfo);

  const isAuth = computed(() => !!accountGeneralInfo.value);

  function $reset() {}

  return {isAuth, $reset};
});
