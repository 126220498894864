import { createUseOpenFetch } from '#imports'
import type { paths as GetJobApiPaths } from '#open-fetch-schemas/get-job-api'

export type OpenFetchClientName = 'getJobApi'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useGetJobApi = createUseOpenFetch<GetJobApiPaths>('getJobApi')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyGetJobApi = createUseOpenFetch<GetJobApiPaths>('getJobApi', true)
