export const enum RegistrationStepEnum {
  Start,
  Confirm,
  Complete,
  End,
  Login,
}

export const useRegistrationStore = defineStore('registrationStore', () => {
  const email = ref<string>();
  const password = ref<string>();
  const registrationId = ref<string>();
  const securityStamp = ref<string>();
  const registrationStep = ref(RegistrationStepEnum.Start);

  function setRegistrationId(value: string) {
    registrationId.value = value;
  }

  function setRegistrationSecurityStamp(value: string) {
    securityStamp.value = value;
  }

  function setRegistrationStep(value: RegistrationStepEnum) {
    registrationStep.value = value;
  }

  function setEmail(value?: string) {
    email.value = value;
  }

  function setPassword(value?: string) {
    password.value = value;
  }

  function $reset() {
    email.value = undefined;
    password.value = undefined;
    registrationId.value = undefined;
    securityStamp.value = undefined;
    registrationStep.value = RegistrationStepEnum.Start;
  }

  return {
    email,
    password,
    registrationId,
    securityStamp,
    registrationStep,
    setRegistrationId,
    setRegistrationSecurityStamp,
    setRegistrationStep,
    setEmail,
    setPassword,
    $reset,
  };
});
