export const enum PasswordRecoveryStepEnum {
  Start,
  SendEmail,
  Confirm,
  Complete,
}

export const usePasswordRecoveryStore = defineStore(
  'passwordRecoveryStore',
  () => {
    const email = ref('');
    const resetCode = ref('');
    const currentStep = ref(PasswordRecoveryStepEnum.Start);

    function setRegistrationStep(value: PasswordRecoveryStepEnum) {
      currentStep.value = value;
    }

    function setEmail(value: string) {
      email.value = value;
    }

    function setResetCode(value: string) {
      resetCode.value = value;
    }

    function $reset() {
      email.value = '';
      resetCode.value = '';
      currentStep.value = PasswordRecoveryStepEnum.Start;
    }

    return {
      email,
      currentStep,
      resetCode,
      setResetCode,
      setRegistrationStep,
      setEmail,
      $reset,
    };
  },
);
