import {useAppToast} from '~/stores/appToast';
import type {AssignmentCategory, AssignmentSubCategory} from '~/types';

export const useCategoriesStore = defineStore('categoriesStore', () => {
  const {showApiErrorToast} = useAppToast();

  let assignmentCategories: AssignmentCategory[] = [];
  let assignmentSubCategories: AssignmentSubCategory[] = [];

  const assignmentCategoriesMap = new Map<string, AssignmentSubCategory>();

  function getAssignmentCategories(): AssignmentCategory[] {
    return assignmentCategories;
  }

  function getAssignmentSubCategories(): AssignmentSubCategory[] {
    return assignmentSubCategories;
  }

  function getAssignmentCategory(
    id: string,
  ): AssignmentSubCategory | undefined {
    return assignmentCategoriesMap.get(id);
  }

  function setAssignmentCategories(value: AssignmentCategory[]) {
    assignmentCategories = value;

    const subCategories = assignmentCategories.reduce(
      (acc, items) => [...acc, ...items.subcategories],
      [] as AssignmentSubCategory[],
    );

    assignmentSubCategories = [...subCategories];

    subCategories.forEach(cat => {
      assignmentCategoriesMap.set(cat.id, cat);
    });
  }

  function fetchAssignmentCategories() {
    return useGetJobApi('/general/categories', {
      method: 'GET',
      onResponseError: showApiErrorToast,
    });
  }

  function $reset() {
    assignmentCategories = [];
    assignmentSubCategories = [];
    assignmentCategoriesMap.clear();
  }

  return {
    getAssignmentCategories,
    getAssignmentCategory,
    getAssignmentSubCategories,
    setAssignmentCategories,
    fetchAssignmentCategories,
    $reset,
  };
});
