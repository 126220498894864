import type {GoogleMapWidgetOptions} from '~/components/GoogleMap/GoogleMapWidget';
import {GoogleMapWidget} from '~/components/GoogleMap/GoogleMapWidget';
import {GOOGLE_MAP_API_KEY} from '~/consts/google-map.const';

export const useGoogleMapStore = defineStore('googleMapStore', () => {
  const googleMapInitialized = ref(false);
  const googleMapInstance = ref<GoogleMapWidget>();
  const goggleMapPlace = ref<google.maps.places.PlaceResult>();

  function setupGoogleMapApi(): Promise<void> {
    return new Promise(resolve => {
      const googleMapScript = document.createElement('script');

      window.initMap = () => {
        googleMapInitialized.value = true;

        resolve();
      };

      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&loading=async&libraries=places&region=cs&language=cs&callback=initMap`,
      );

      document.head.appendChild(googleMapScript);
    });
  }

  function createGoogleMap(options: GoogleMapWidgetOptions) {
    googleMapInstance.value = new GoogleMapWidget(options);

    return googleMapInstance.value;
  }

  function setGoggleMapPlace(place?: google.maps.places.PlaceResult) {
    goggleMapPlace.value = place;
  }

  function $reset() {
    googleMapInitialized.value = false;
    googleMapInstance.value = undefined;
    goggleMapPlace.value = undefined;
  }

  return {
    googleMapInitialized,
    goggleMapPlace,
    setupGoogleMapApi,
    createGoogleMap,
    setGoggleMapPlace,
    $reset,
  };
});
