<template>
  <div v-click-outside="closeDropdown" class="dropdown">
    <div
      :class="[
        'dropdown-icon',
        {
          'dropdown-icon-active': isOpen,
          'dropdown-icon-disabled': disabled,
        },
      ]"
      @click="openDropdown"
    >
      <slot name="icon" />
    </div>
    <transition name="fade">
      <div v-show="isOpen" :class="['dropdown-list', `${positions[position]}`]">
        <slot name="list" />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
  const {position, disabled} = withDefaults(
    defineProps<{
      position?: string;
      disabled?: boolean;
    }>(),
    {
      position: 'left-bottom',
      disabled: false,
    },
  );

  const route = useRoute();

  const isOpen = ref(false);
  const positions = {
    'left-bottom': 'dropdown-list-left-bottom',
    bottom: 'dropdown-list-bottom',
    top: 'dropdown-list-top',
  };

  watch(
    () => route.path,
    () => {
      closeDropdown();
    },
  );

  function openDropdown() {
    if (disabled) return;

    isOpen.value = !isOpen.value;
  }

  function closeDropdown() {
    isOpen.value = false;
  }

  defineExpose({
    closeDropdown,
  });
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .dropdown {
    position: relative;

    &-icon {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &-disabled {
        cursor: not-allowed;
      }

      .dropdown-svg {
        fill: #1e272f;

        &-grey {
          fill: #999da1;
        }
      }

      &-active {
        .dropdown-svg {
          fill: #ff6b17;
          transform: rotate(-180deg);
        }
      }
    }

    &-list {
      position: absolute;
      background: #fff;
      box-shadow: 0 38px 202px rgb(30 39 47 / 25%);
      border-radius: 21px;
      padding: 24px 0;
      z-index: 2;

      &-left-bottom {
        left: -210px;
        top: 68px;
      }

      &-bottom {
        left: -50%;
      }

      &-top {
        top: -300%;
      }
    }
  }
</style>
