import {DynamicDialogWrapper, ModalRegistration} from '#components';
import type {NavigationGuardNext, RouteLocationNormalized} from 'vue-router';

export default async function (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext,
): Promise<void> {
  try {
    const auth = await checkAuthAfterAppInit();

    next(auth);
  } catch (error) {
    const {status} = await checkAuthBeforeAppInit();

    if (status.value === 'success') {
      next();
    } else {
      // TODO: understand how to use router instead window location
      window.location.href = `/authorize${to.path}`;
    }
  }
}

function checkAuthAfterAppInit() {
  return new Promise<boolean>(resolve => {
    const useDialog = useDialogStore();
    const useAuth = useAuthorizationStore();
    const {isAuth} = storeToRefs(useAuth);

    if (isAuth.value) {
      resolve(true);
    } else {
      useDialog.openDialog(DynamicDialogWrapper, {
        data: {
          component: ModalRegistration,
          props: {
            afterLoginCallback: () => resolve(true),
            afterRegistrationCallback: () => resolve(true),
          },
        },
        onClose: () => {
          setTimeout(() => {
            resolve(false);
          });
        },
      });
    }
  });
}

function checkAuthBeforeAppInit() {
  return useFetch('/api/account/profile/general-info', {
    method: 'GET',
  });
}
