import type {AssignmentCategory} from '~/types';

export default defineNuxtRouteMiddleware(async _ => {
  const {
    fetchAssignmentCategories,
    setAssignmentCategories,
    getAssignmentCategories,
  } = useCategoriesStore();

  if (!getAssignmentCategories().length) {
    const {data} = await fetchAssignmentCategories();

    setAssignmentCategories((data.value?.items || []) as AssignmentCategory[]);
  }
});
