import {useLocationStore, type Location} from '~/stores/location';

export default defineNuxtRouteMiddleware(async _ => {
  const {fetchGeneralLocation, setLocationsList, getLocationList} =
    useLocationStore();

  if (!getLocationList().length) {
    const {data} = await fetchGeneralLocation();

    setLocationsList((data.value?.items || []) as Location[]);
  }
});
