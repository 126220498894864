import type {LocationQuery} from 'vue-router';
import {useRegistrationStore} from '~/stores/registration';
import type {GetJobApiError} from '~/types';

export interface ExternalRegistrationRouteQuery extends LocationQuery {
  externalRegistration: string | null;
  data: string | null;
  error: string | null;
}

export interface ExternalRegistrationDataValue {
  value?: {
    registrationId: string;
    securityStamp: string;
    additionalItems?: {
      firstName: string;
      lastName: string;
      email: string;
    };
  };
}

export enum ExternalRegistrationProvider {
  Google = 'google',
  Facebook = 'facebook',
}

export const useExternalRegistrationStore = defineStore(
  'externalRegistrationStore',
  () => {
    const route = useRoute();
    const registrationStore = useRegistrationStore();
    const {setRegistrationId, setRegistrationSecurityStamp} = registrationStore;

    const {externalRegistration, data, error} =
      route.query as ExternalRegistrationRouteQuery;

    function startExternalRegistration(provider: ExternalRegistrationProvider) {
      window.open(
        `/api/account/registration/external/${provider.toLowerCase()}-start?url=${window.location.href}?externalRegistration=true`,
        '_self',
      );
    }

    function startExternalLogin(provider: ExternalRegistrationProvider) {
      window.open(`/api/account/login-with-${provider.toLowerCase()}`, '_self');
    }

    function isExternalRegistration() {
      return externalRegistration && externalRegistration === 'true';
    }

    function getExternalRegistrationValue():
      | ExternalRegistrationDataValue
      | undefined {
      if (!data) return;

      try {
        return JSON.parse(atob(data)) as ExternalRegistrationDataValue;
      } catch (error: unknown) {
        console.warn(error);
      }
    }

    function getExternalRegistrationError(): GetJobApiError | undefined {
      if (!error) return;

      try {
        return JSON.parse(atob(error)) as GetJobApiError;
      } catch (error: unknown) {
        console.warn(error);
      }
    }

    function applyExternalRegistrationMetaData(
      data: ExternalRegistrationDataValue,
    ) {
      const {registrationId, securityStamp} = data.value!;

      setRegistrationId(registrationId);
      setRegistrationSecurityStamp(securityStamp);
    }

    function $reset() {
      // Mock implementation
    }

    return {
      startExternalLogin,
      startExternalRegistration,
      getExternalRegistrationValue,
      getExternalRegistrationError,
      isExternalRegistration,
      applyExternalRegistrationMetaData,
      $reset,
    };
  },
);
