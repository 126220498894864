<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 4.20833C0.5 2.60954 0.5 1.81014 0.688758 1.5412C0.877516 1.27227 1.62916 1.01498 3.13246 0.500396L3.41886 0.402358C4.20249 0.134119 4.59431 0 5 0C5.40569 0 5.79751 0.13412 6.58114 0.402359L6.86755 0.500396C8.37084 1.01498 9.12248 1.27227 9.31124 1.5412C9.5 1.81014 9.5 2.60954 9.5 4.20833V4.99568C9.5 7.81472 7.38052 9.18274 6.05072 9.76364C5.68999 9.92121 5.50963 10 5 10C4.49037 10 4.31001 9.92121 3.94928 9.76364C2.61948 9.18274 0.5 7.81472 0.5 4.99568V4.20833ZM5 2.625C5.20711 2.625 5.375 2.79289 5.375 3V5C5.375 5.20711 5.20711 5.375 5 5.375C4.79289 5.375 4.625 5.20711 4.625 5V3C4.625 2.79289 4.79289 2.625 5 2.625ZM5 7C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6C4.72386 6 4.5 6.22386 4.5 6.5C4.5 6.77614 4.72386 7 5 7Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
