import type {UserInfo} from '~/stores/user';
import getFullName from '~/utils/getFullName';
import getInitials from '~/utils/getInitials';

export type AccountRole = 'Client' | 'ServiceProvider';

export interface AccountGeneralInfo extends UserInfo {
  isServiceProvider: boolean;
}

export interface AccountGeneralReview {
  authorFirstName: string;
  authorLastName: string;
  authorProfilePhotoUrl: string;
  createdAtUtc: string;
  text: string;
  rating: number;
}

export const useAccountProfileStore = defineStore('accountProfileStore', () => {
  const {showApiErrorToast} = useAppToast();

  // TODO: need refactor. Move to registration store
  const accountRole = ref<AccountRole>();
  const accountGeneralInfo = ref<AccountGeneralInfo>();
  const accountGeneralReviews = ref<AccountGeneralReview[]>([]);
  const accountPhotoUrl = computed(() => accountGeneralInfo.value?.photoUrl);
  const accountInitials = computed(() =>
    getInitials(
      accountGeneralInfo.value!.firstName,
      accountGeneralInfo.value!.lastName,
    ),
  );

  const accountFullName = computed(() =>
    getFullName(
      accountGeneralInfo.value!.firstName,
      accountGeneralInfo.value!.lastName,
    ),
  );

  const isServiceProvider = computed(
    () => !!accountGeneralInfo.value?.isServiceProvider,
  );

  function setAccountRole(role: AccountRole) {
    accountRole.value = role;
  }

  function setAccountGeneralInfo(info: AccountGeneralInfo) {
    accountGeneralInfo.value = info;
  }

  function setAccountGeneralReviews(reviews: AccountGeneralReview[]) {
    accountGeneralReviews.value = [...accountGeneralReviews.value, ...reviews];
  }

  function setAccountPhotoUrl(photoUrl: string) {
    if (accountGeneralInfo.value) {
      accountGeneralInfo.value.photoUrl = photoUrl;
    }
  }

  function fetchAccountAuthInfo() {
    return useGetJobApi('/account/profile/general-info', {
      method: 'GET',
    });
  }

  function fetchAccountProfileGeneralInfo() {
    return useGetJobApi('/account/profile/general-info', {
      method: 'GET',
      onResponseError: showApiErrorToast,
    });
  }

  function fetchAccountProfileGeneralReviews() {
    return useGetJobApi('/account/profile/general-reviews', {
      method: 'GET',
      params: {
        offset: accountGeneralReviews.value.length,
        count: 3,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function updateAccountProfileBio(bio: string) {
    return useGetJobApi('/account/profile/update-bio', {
      method: 'POST',
      body: {
        bio,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function convertAccountToServiceProvider() {
    return useGetJobApi('/account/profile/convert-to-service-provider', {
      method: 'POST',
      onResponse: ({response}) => {
        if (response.status === 200) {
          accountGeneralInfo.value!.isServiceProvider = true;
        }
      },
      onResponseError: showApiErrorToast,
    });
  }

  function updateAccountProfilePhoto(photo: File) {
    const formData = new FormData();

    formData.append('file', photo);

    return useGetJobApi('/account/profile/update-photo', {
      method: 'POST',
      // eslint-disable-next-line
      // @ts-ignore
      body: formData,
      onResponseError: showApiErrorToast,
    });
  }

  function $reset() {
    accountRole.value = undefined;
    accountGeneralInfo.value = undefined;
    accountGeneralReviews.value = [];
  }

  return {
    accountRole,
    accountGeneralInfo,
    accountGeneralReviews,
    accountPhotoUrl,
    accountInitials,
    accountFullName,
    isServiceProvider,
    setAccountRole,
    setAccountPhotoUrl,
    fetchAccountAuthInfo,
    fetchAccountProfileGeneralInfo,
    fetchAccountProfileGeneralReviews,
    convertAccountToServiceProvider,
    updateAccountProfileBio,
    updateAccountProfilePhoto,
    setAccountGeneralInfo,
    setAccountGeneralReviews,
    $reset,
  };
});
