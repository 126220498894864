<template>
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12C0.71667 12 0.479003 11.904 0.287003 11.712C0.0950034 11.52 -0.000663206 11.2827 3.46021e-06 11C3.46021e-06 10.7167 0.0960036 10.479 0.288004 10.287C0.480004 10.095 0.717337 9.99934 1 10H17C17.2833 10 17.521 10.096 17.713 10.288C17.905 10.48 18.0007 10.7173 18 11C18 11.2833 17.904 11.521 17.712 11.713C17.52 11.905 17.2827 12.0007 17 12H1ZM1 7C0.71667 7 0.479003 6.904 0.287003 6.712C0.0950034 6.52 -0.000663206 6.28267 3.46021e-06 6C3.46021e-06 5.71667 0.0960036 5.479 0.288004 5.287C0.480004 5.095 0.717337 4.99934 1 5H17C17.2833 5 17.521 5.096 17.713 5.288C17.905 5.48 18.0007 5.71734 18 6C18 6.28334 17.904 6.521 17.712 6.713C17.52 6.905 17.2827 7.00067 17 7H1ZM1 2C0.71667 2 0.479003 1.904 0.287003 1.712C0.0950034 1.52 -0.000663206 1.28267 3.46021e-06 1C3.46021e-06 0.71667 0.0960036 0.479004 0.288004 0.287004C0.480004 0.0950036 0.717337 -0.000663206 1 3.4602e-06H17C17.2833 3.4602e-06 17.521 0.0960036 17.713 0.288004C17.905 0.480004 18.0007 0.717337 18 1C18 1.28334 17.904 1.521 17.712 1.713C17.52 1.905 17.2827 2.00067 17 2H1Z"
      fill="#1E272F"
    />
  </svg>
</template>

<script setup lang="js"></script>

<style lang="scss" scoped></style>
