import {GOOGLE_MAP_PACES_FIELDS} from '~/consts/google-map.const';

declare global {
  interface Window {
    initMap?: () => void;
    placeChangedCallback: (place: google.maps.places.PlaceResult) => void;
    google?: unknown;
  }
}

export interface GoogleMapWidgetOptions {
  mapContainer: HTMLDivElement;
  initialPlace?: google.maps.places.PlaceResult;
  useAutocomplete?: boolean;
}

export class GoogleMapWidget {
  private readonly useGoogleMap = useGoogleMapStore();

  private map!: google.maps.Map;
  private marker!: google.maps.Marker;
  private infoWindow!: google.maps.InfoWindow;
  private infoWindowDiv!: HTMLDivElement;
  private infoWindowPlaceName!: HTMLDivElement;
  private infoWindowPlaceAddress!: HTMLDivElement;

  constructor(private options: GoogleMapWidgetOptions) {
    window.placeChangedCallback = this.useGoogleMap.setGoggleMapPlace;

    this.initMap();
  }

  private initMap(): void {
    this.map = new google.maps.Map(this.options.mapContainer, {
      center: {lat: 50.073658, lng: 14.41854},
      zoom: 13,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
    });

    if (this.options.useAutocomplete) {
      const input = document.getElementById('pac-input') as HTMLInputElement;

      // Specify just the place data fields that you need.
      const autocomplete = new google.maps.places.Autocomplete(input, {
        fields: GOOGLE_MAP_PACES_FIELDS,
      });

      autocomplete.bindTo('bounds', this.map);

      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      autocomplete.addListener('place_changed', () => {
        this.infoWindow.close();

        const place = autocomplete.getPlace();

        this.setPlace(place);
      });
    }

    this.infoWindow = new google.maps.InfoWindow();
    this.infoWindowDiv = document.getElementById(
      'infowindow-content',
    ) as HTMLDivElement;

    this.infoWindowPlaceName = document.createElement('div');
    this.infoWindowPlaceName.setAttribute('id', 'google-map-place-name');
    this.infoWindowPlaceAddress = document.createElement('div');
    this.infoWindowDiv.append(this.infoWindowPlaceName);
    this.infoWindowDiv.append(this.infoWindowPlaceAddress);

    this.infoWindow.setContent(this.infoWindowDiv);

    // TODO: move to AdvancedMarkerElement
    this.marker = new google.maps.Marker({
      map: this.map,
      icon: '/img/map-marker.svg',
    });

    this.marker.addListener('click', () => {
      this.infoWindow.open(this.map, this.marker);
    });

    const {initialPlace} = this.options;

    if (initialPlace) {
      this.setPlace(initialPlace);
    }
  }

  private setPlace(place: google.maps.places.PlaceResult): void {
    window.placeChangedCallback(place);

    if (!place.geometry || !place.geometry.location) {
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    // Set the position of the marker using the place ID and location.
    // @ts-expect-error This should be in @typings/googlemaps.
    this.marker.setPlace({
      placeId: place.place_id,
      location: place.geometry.location,
    });

    this.marker.setVisible(true);

    this.infoWindowPlaceName.textContent = place.name as string;
    this.infoWindowPlaceAddress.textContent = place.formatted_address as string;

    this.infoWindow.open(this.map, this.marker);
  }
}
