import {type AccountGeneralInfo} from '~/stores/accountProfile';

export default defineNuxtRouteMiddleware(async _ => {
  // Disable auth checking on server side
  if (import.meta.server) return;

  const {accountGeneralInfo, fetchAccountAuthInfo, setAccountGeneralInfo} =
    useAccountProfileStore();

  if (!accountGeneralInfo) {
    const {data} = await fetchAccountAuthInfo();

    setAccountGeneralInfo(data.value as unknown as AccountGeneralInfo);
  }
});
