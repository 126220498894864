import { default as indexQal2Ejil6TMeta } from "/usr/src/nuxt-app/pages/Assignment/index.vue?macro=true";
import { default as indexaObk6aUp8LMeta } from "/usr/src/nuxt-app/pages/Assignments/index.vue?macro=true";
import { default as indexhb6kdJs5uhMeta } from "/usr/src/nuxt-app/pages/Categories/index.vue?macro=true";
import { default as indexJfvJG7Pxo1Meta } from "/usr/src/nuxt-app/pages/CreateAssignment/index.vue?macro=true";
import { default as indexnmjgkLq8hdMeta } from "/usr/src/nuxt-app/pages/FAQ/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as loginFailedB40IJTQGv5Meta } from "/usr/src/nuxt-app/pages/loginFailed.vue?macro=true";
import { default as indexiSMCxz8dRdMeta } from "/usr/src/nuxt-app/pages/PrivacyPolicy/index.vue?macro=true";
import { default as indexsVhVnscVUuMeta } from "/usr/src/nuxt-app/pages/Profile/AssignmentHistory/index.vue?macro=true";
import { default as indexYS6aWiQWAyMeta } from "/usr/src/nuxt-app/pages/Profile/index.vue?macro=true";
import { default as indexRsfsOEAB0vMeta } from "/usr/src/nuxt-app/pages/Profile/Security/index.vue?macro=true";
import { default as indexlpCRPz0Qn9Meta } from "/usr/src/nuxt-app/pages/Profile/Settings/index.vue?macro=true";
import { default as indexQTIgzp8EWzMeta } from "/usr/src/nuxt-app/pages/PublicOffer/index.vue?macro=true";
import { default as index8A47q8RLiYMeta } from "/usr/src/nuxt-app/pages/User/index.vue?macro=true";
export default [
  {
    name: indexQal2Ejil6TMeta?.name ?? "Assignment___en",
    path: indexQal2Ejil6TMeta?.path ?? "/en/Assignment",
    meta: indexQal2Ejil6TMeta || {},
    alias: indexQal2Ejil6TMeta?.alias || [],
    redirect: indexQal2Ejil6TMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexQal2Ejil6TMeta?.name ?? "Assignment___ru",
    path: indexQal2Ejil6TMeta?.path ?? "/ru/Assignment",
    meta: indexQal2Ejil6TMeta || {},
    alias: indexQal2Ejil6TMeta?.alias || [],
    redirect: indexQal2Ejil6TMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexQal2Ejil6TMeta?.name ?? "Assignment___cs___default",
    path: indexQal2Ejil6TMeta?.path ?? "/Assignment",
    meta: indexQal2Ejil6TMeta || {},
    alias: indexQal2Ejil6TMeta?.alias || [],
    redirect: indexQal2Ejil6TMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexQal2Ejil6TMeta?.name ?? "Assignment___cs",
    path: indexQal2Ejil6TMeta?.path ?? "/cs/Assignment",
    meta: indexQal2Ejil6TMeta || {},
    alias: indexQal2Ejil6TMeta?.alias || [],
    redirect: indexQal2Ejil6TMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexaObk6aUp8LMeta?.name ?? "Assignments___en",
    path: indexaObk6aUp8LMeta?.path ?? "/en/Assignments",
    meta: indexaObk6aUp8LMeta || {},
    alias: indexaObk6aUp8LMeta?.alias || [],
    redirect: indexaObk6aUp8LMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignments/index.vue").then(m => m.default || m)
  },
  {
    name: indexaObk6aUp8LMeta?.name ?? "Assignments___ru",
    path: indexaObk6aUp8LMeta?.path ?? "/ru/Assignments",
    meta: indexaObk6aUp8LMeta || {},
    alias: indexaObk6aUp8LMeta?.alias || [],
    redirect: indexaObk6aUp8LMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignments/index.vue").then(m => m.default || m)
  },
  {
    name: indexaObk6aUp8LMeta?.name ?? "Assignments___cs___default",
    path: indexaObk6aUp8LMeta?.path ?? "/Assignments",
    meta: indexaObk6aUp8LMeta || {},
    alias: indexaObk6aUp8LMeta?.alias || [],
    redirect: indexaObk6aUp8LMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignments/index.vue").then(m => m.default || m)
  },
  {
    name: indexaObk6aUp8LMeta?.name ?? "Assignments___cs",
    path: indexaObk6aUp8LMeta?.path ?? "/cs/Assignments",
    meta: indexaObk6aUp8LMeta || {},
    alias: indexaObk6aUp8LMeta?.alias || [],
    redirect: indexaObk6aUp8LMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Assignments/index.vue").then(m => m.default || m)
  },
  {
    name: indexhb6kdJs5uhMeta?.name ?? "Categories___en",
    path: indexhb6kdJs5uhMeta?.path ?? "/en/Categories",
    meta: indexhb6kdJs5uhMeta || {},
    alias: indexhb6kdJs5uhMeta?.alias || [],
    redirect: indexhb6kdJs5uhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexhb6kdJs5uhMeta?.name ?? "Categories___ru",
    path: indexhb6kdJs5uhMeta?.path ?? "/ru/Categories",
    meta: indexhb6kdJs5uhMeta || {},
    alias: indexhb6kdJs5uhMeta?.alias || [],
    redirect: indexhb6kdJs5uhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexhb6kdJs5uhMeta?.name ?? "Categories___cs___default",
    path: indexhb6kdJs5uhMeta?.path ?? "/Categories",
    meta: indexhb6kdJs5uhMeta || {},
    alias: indexhb6kdJs5uhMeta?.alias || [],
    redirect: indexhb6kdJs5uhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexhb6kdJs5uhMeta?.name ?? "Categories___cs",
    path: indexhb6kdJs5uhMeta?.path ?? "/cs/Categories",
    meta: indexhb6kdJs5uhMeta || {},
    alias: indexhb6kdJs5uhMeta?.alias || [],
    redirect: indexhb6kdJs5uhMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfvJG7Pxo1Meta?.name ?? "CreateAssignment___en",
    path: indexJfvJG7Pxo1Meta?.path ?? "/en/CreateAssignment",
    meta: indexJfvJG7Pxo1Meta || {},
    alias: indexJfvJG7Pxo1Meta?.alias || [],
    redirect: indexJfvJG7Pxo1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/CreateAssignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfvJG7Pxo1Meta?.name ?? "CreateAssignment___ru",
    path: indexJfvJG7Pxo1Meta?.path ?? "/ru/CreateAssignment",
    meta: indexJfvJG7Pxo1Meta || {},
    alias: indexJfvJG7Pxo1Meta?.alias || [],
    redirect: indexJfvJG7Pxo1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/CreateAssignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfvJG7Pxo1Meta?.name ?? "CreateAssignment___cs___default",
    path: indexJfvJG7Pxo1Meta?.path ?? "/CreateAssignment",
    meta: indexJfvJG7Pxo1Meta || {},
    alias: indexJfvJG7Pxo1Meta?.alias || [],
    redirect: indexJfvJG7Pxo1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/CreateAssignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexJfvJG7Pxo1Meta?.name ?? "CreateAssignment___cs",
    path: indexJfvJG7Pxo1Meta?.path ?? "/cs/CreateAssignment",
    meta: indexJfvJG7Pxo1Meta || {},
    alias: indexJfvJG7Pxo1Meta?.alias || [],
    redirect: indexJfvJG7Pxo1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/CreateAssignment/index.vue").then(m => m.default || m)
  },
  {
    name: indexnmjgkLq8hdMeta?.name ?? "FAQ___en",
    path: indexnmjgkLq8hdMeta?.path ?? "/en/FAQ",
    meta: indexnmjgkLq8hdMeta || {},
    alias: indexnmjgkLq8hdMeta?.alias || [],
    redirect: indexnmjgkLq8hdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/FAQ/index.vue").then(m => m.default || m)
  },
  {
    name: indexnmjgkLq8hdMeta?.name ?? "FAQ___ru",
    path: indexnmjgkLq8hdMeta?.path ?? "/ru/FAQ",
    meta: indexnmjgkLq8hdMeta || {},
    alias: indexnmjgkLq8hdMeta?.alias || [],
    redirect: indexnmjgkLq8hdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/FAQ/index.vue").then(m => m.default || m)
  },
  {
    name: indexnmjgkLq8hdMeta?.name ?? "FAQ___cs___default",
    path: indexnmjgkLq8hdMeta?.path ?? "/FAQ",
    meta: indexnmjgkLq8hdMeta || {},
    alias: indexnmjgkLq8hdMeta?.alias || [],
    redirect: indexnmjgkLq8hdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/FAQ/index.vue").then(m => m.default || m)
  },
  {
    name: indexnmjgkLq8hdMeta?.name ?? "FAQ___cs",
    path: indexnmjgkLq8hdMeta?.path ?? "/cs/FAQ",
    meta: indexnmjgkLq8hdMeta || {},
    alias: indexnmjgkLq8hdMeta?.alias || [],
    redirect: indexnmjgkLq8hdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/FAQ/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en",
    path: indexAoBMx6PnC1Meta?.path ?? "/en",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___ru",
    path: indexAoBMx6PnC1Meta?.path ?? "/ru",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___cs___default",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___cs",
    path: indexAoBMx6PnC1Meta?.path ?? "/cs",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginFailedB40IJTQGv5Meta?.name ?? "loginFailed___en",
    path: loginFailedB40IJTQGv5Meta?.path ?? "/en/loginFailed",
    meta: loginFailedB40IJTQGv5Meta || {},
    alias: loginFailedB40IJTQGv5Meta?.alias || [],
    redirect: loginFailedB40IJTQGv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/loginFailed.vue").then(m => m.default || m)
  },
  {
    name: loginFailedB40IJTQGv5Meta?.name ?? "loginFailed___ru",
    path: loginFailedB40IJTQGv5Meta?.path ?? "/ru/loginFailed",
    meta: loginFailedB40IJTQGv5Meta || {},
    alias: loginFailedB40IJTQGv5Meta?.alias || [],
    redirect: loginFailedB40IJTQGv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/loginFailed.vue").then(m => m.default || m)
  },
  {
    name: loginFailedB40IJTQGv5Meta?.name ?? "loginFailed___cs___default",
    path: loginFailedB40IJTQGv5Meta?.path ?? "/loginFailed",
    meta: loginFailedB40IJTQGv5Meta || {},
    alias: loginFailedB40IJTQGv5Meta?.alias || [],
    redirect: loginFailedB40IJTQGv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/loginFailed.vue").then(m => m.default || m)
  },
  {
    name: loginFailedB40IJTQGv5Meta?.name ?? "loginFailed___cs",
    path: loginFailedB40IJTQGv5Meta?.path ?? "/cs/loginFailed",
    meta: loginFailedB40IJTQGv5Meta || {},
    alias: loginFailedB40IJTQGv5Meta?.alias || [],
    redirect: loginFailedB40IJTQGv5Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/loginFailed.vue").then(m => m.default || m)
  },
  {
    name: indexiSMCxz8dRdMeta?.name ?? "PrivacyPolicy___en",
    path: indexiSMCxz8dRdMeta?.path ?? "/en/PrivacyPolicy",
    meta: indexiSMCxz8dRdMeta || {},
    alias: indexiSMCxz8dRdMeta?.alias || [],
    redirect: indexiSMCxz8dRdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PrivacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSMCxz8dRdMeta?.name ?? "PrivacyPolicy___ru",
    path: indexiSMCxz8dRdMeta?.path ?? "/ru/PrivacyPolicy",
    meta: indexiSMCxz8dRdMeta || {},
    alias: indexiSMCxz8dRdMeta?.alias || [],
    redirect: indexiSMCxz8dRdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PrivacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSMCxz8dRdMeta?.name ?? "PrivacyPolicy___cs___default",
    path: indexiSMCxz8dRdMeta?.path ?? "/PrivacyPolicy",
    meta: indexiSMCxz8dRdMeta || {},
    alias: indexiSMCxz8dRdMeta?.alias || [],
    redirect: indexiSMCxz8dRdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PrivacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSMCxz8dRdMeta?.name ?? "PrivacyPolicy___cs",
    path: indexiSMCxz8dRdMeta?.path ?? "/cs/PrivacyPolicy",
    meta: indexiSMCxz8dRdMeta || {},
    alias: indexiSMCxz8dRdMeta?.alias || [],
    redirect: indexiSMCxz8dRdMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PrivacyPolicy/index.vue").then(m => m.default || m)
  },
  {
    name: indexsVhVnscVUuMeta?.name ?? "Profile-AssignmentHistory___en",
    path: indexsVhVnscVUuMeta?.path ?? "/en/Profile/AssignmentHistory",
    meta: indexsVhVnscVUuMeta || {},
    alias: indexsVhVnscVUuMeta?.alias || [],
    redirect: indexsVhVnscVUuMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/AssignmentHistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexsVhVnscVUuMeta?.name ?? "Profile-AssignmentHistory___ru",
    path: indexsVhVnscVUuMeta?.path ?? "/ru/Profile/AssignmentHistory",
    meta: indexsVhVnscVUuMeta || {},
    alias: indexsVhVnscVUuMeta?.alias || [],
    redirect: indexsVhVnscVUuMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/AssignmentHistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexsVhVnscVUuMeta?.name ?? "Profile-AssignmentHistory___cs___default",
    path: indexsVhVnscVUuMeta?.path ?? "/Profile/AssignmentHistory",
    meta: indexsVhVnscVUuMeta || {},
    alias: indexsVhVnscVUuMeta?.alias || [],
    redirect: indexsVhVnscVUuMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/AssignmentHistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexsVhVnscVUuMeta?.name ?? "Profile-AssignmentHistory___cs",
    path: indexsVhVnscVUuMeta?.path ?? "/cs/Profile/AssignmentHistory",
    meta: indexsVhVnscVUuMeta || {},
    alias: indexsVhVnscVUuMeta?.alias || [],
    redirect: indexsVhVnscVUuMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/AssignmentHistory/index.vue").then(m => m.default || m)
  },
  {
    name: indexYS6aWiQWAyMeta?.name ?? "Profile___en",
    path: indexYS6aWiQWAyMeta?.path ?? "/en/Profile",
    meta: indexYS6aWiQWAyMeta || {},
    alias: indexYS6aWiQWAyMeta?.alias || [],
    redirect: indexYS6aWiQWAyMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexYS6aWiQWAyMeta?.name ?? "Profile___ru",
    path: indexYS6aWiQWAyMeta?.path ?? "/ru/Profile",
    meta: indexYS6aWiQWAyMeta || {},
    alias: indexYS6aWiQWAyMeta?.alias || [],
    redirect: indexYS6aWiQWAyMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexYS6aWiQWAyMeta?.name ?? "Profile___cs___default",
    path: indexYS6aWiQWAyMeta?.path ?? "/Profile",
    meta: indexYS6aWiQWAyMeta || {},
    alias: indexYS6aWiQWAyMeta?.alias || [],
    redirect: indexYS6aWiQWAyMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexYS6aWiQWAyMeta?.name ?? "Profile___cs",
    path: indexYS6aWiQWAyMeta?.path ?? "/cs/Profile",
    meta: indexYS6aWiQWAyMeta || {},
    alias: indexYS6aWiQWAyMeta?.alias || [],
    redirect: indexYS6aWiQWAyMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexRsfsOEAB0vMeta?.name ?? "Profile-Security___en",
    path: indexRsfsOEAB0vMeta?.path ?? "/en/Profile/Security",
    meta: indexRsfsOEAB0vMeta || {},
    alias: indexRsfsOEAB0vMeta?.alias || [],
    redirect: indexRsfsOEAB0vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Security/index.vue").then(m => m.default || m)
  },
  {
    name: indexRsfsOEAB0vMeta?.name ?? "Profile-Security___ru",
    path: indexRsfsOEAB0vMeta?.path ?? "/ru/Profile/Security",
    meta: indexRsfsOEAB0vMeta || {},
    alias: indexRsfsOEAB0vMeta?.alias || [],
    redirect: indexRsfsOEAB0vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Security/index.vue").then(m => m.default || m)
  },
  {
    name: indexRsfsOEAB0vMeta?.name ?? "Profile-Security___cs___default",
    path: indexRsfsOEAB0vMeta?.path ?? "/Profile/Security",
    meta: indexRsfsOEAB0vMeta || {},
    alias: indexRsfsOEAB0vMeta?.alias || [],
    redirect: indexRsfsOEAB0vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Security/index.vue").then(m => m.default || m)
  },
  {
    name: indexRsfsOEAB0vMeta?.name ?? "Profile-Security___cs",
    path: indexRsfsOEAB0vMeta?.path ?? "/cs/Profile/Security",
    meta: indexRsfsOEAB0vMeta || {},
    alias: indexRsfsOEAB0vMeta?.alias || [],
    redirect: indexRsfsOEAB0vMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Security/index.vue").then(m => m.default || m)
  },
  {
    name: indexlpCRPz0Qn9Meta?.name ?? "Profile-Settings___en",
    path: indexlpCRPz0Qn9Meta?.path ?? "/en/Profile/Settings",
    meta: indexlpCRPz0Qn9Meta || {},
    alias: indexlpCRPz0Qn9Meta?.alias || [],
    redirect: indexlpCRPz0Qn9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlpCRPz0Qn9Meta?.name ?? "Profile-Settings___ru",
    path: indexlpCRPz0Qn9Meta?.path ?? "/ru/Profile/Settings",
    meta: indexlpCRPz0Qn9Meta || {},
    alias: indexlpCRPz0Qn9Meta?.alias || [],
    redirect: indexlpCRPz0Qn9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlpCRPz0Qn9Meta?.name ?? "Profile-Settings___cs___default",
    path: indexlpCRPz0Qn9Meta?.path ?? "/Profile/Settings",
    meta: indexlpCRPz0Qn9Meta || {},
    alias: indexlpCRPz0Qn9Meta?.alias || [],
    redirect: indexlpCRPz0Qn9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlpCRPz0Qn9Meta?.name ?? "Profile-Settings___cs",
    path: indexlpCRPz0Qn9Meta?.path ?? "/cs/Profile/Settings",
    meta: indexlpCRPz0Qn9Meta || {},
    alias: indexlpCRPz0Qn9Meta?.alias || [],
    redirect: indexlpCRPz0Qn9Meta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/Profile/Settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexQTIgzp8EWzMeta?.name ?? "PublicOffer___en",
    path: indexQTIgzp8EWzMeta?.path ?? "/en/PublicOffer",
    meta: indexQTIgzp8EWzMeta || {},
    alias: indexQTIgzp8EWzMeta?.alias || [],
    redirect: indexQTIgzp8EWzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PublicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexQTIgzp8EWzMeta?.name ?? "PublicOffer___ru",
    path: indexQTIgzp8EWzMeta?.path ?? "/ru/PublicOffer",
    meta: indexQTIgzp8EWzMeta || {},
    alias: indexQTIgzp8EWzMeta?.alias || [],
    redirect: indexQTIgzp8EWzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PublicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexQTIgzp8EWzMeta?.name ?? "PublicOffer___cs___default",
    path: indexQTIgzp8EWzMeta?.path ?? "/PublicOffer",
    meta: indexQTIgzp8EWzMeta || {},
    alias: indexQTIgzp8EWzMeta?.alias || [],
    redirect: indexQTIgzp8EWzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PublicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: indexQTIgzp8EWzMeta?.name ?? "PublicOffer___cs",
    path: indexQTIgzp8EWzMeta?.path ?? "/cs/PublicOffer",
    meta: indexQTIgzp8EWzMeta || {},
    alias: indexQTIgzp8EWzMeta?.alias || [],
    redirect: indexQTIgzp8EWzMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/PublicOffer/index.vue").then(m => m.default || m)
  },
  {
    name: index8A47q8RLiYMeta?.name ?? "User___en",
    path: index8A47q8RLiYMeta?.path ?? "/en/User",
    meta: index8A47q8RLiYMeta || {},
    alias: index8A47q8RLiYMeta?.alias || [],
    redirect: index8A47q8RLiYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/User/index.vue").then(m => m.default || m)
  },
  {
    name: index8A47q8RLiYMeta?.name ?? "User___ru",
    path: index8A47q8RLiYMeta?.path ?? "/ru/User",
    meta: index8A47q8RLiYMeta || {},
    alias: index8A47q8RLiYMeta?.alias || [],
    redirect: index8A47q8RLiYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/User/index.vue").then(m => m.default || m)
  },
  {
    name: index8A47q8RLiYMeta?.name ?? "User___cs___default",
    path: index8A47q8RLiYMeta?.path ?? "/User",
    meta: index8A47q8RLiYMeta || {},
    alias: index8A47q8RLiYMeta?.alias || [],
    redirect: index8A47q8RLiYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/User/index.vue").then(m => m.default || m)
  },
  {
    name: index8A47q8RLiYMeta?.name ?? "User___cs",
    path: index8A47q8RLiYMeta?.path ?? "/cs/User",
    meta: index8A47q8RLiYMeta || {},
    alias: index8A47q8RLiYMeta?.alias || [],
    redirect: index8A47q8RLiYMeta?.redirect,
    component: () => import("/usr/src/nuxt-app/pages/User/index.vue").then(m => m.default || m)
  }
]